
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import pagination from 'vue-bootstrap-pagination'
import {reorderhistory}  from "@/api/reorder";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "reorder",
  components: {
    // CompanyPolicy,
    // ECShopAdvisory
  },
  data() {
    return { 
      list : [],
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12,
      },
       loading: true,
    }
  },
  mounted(){
      this.reorderhistory();
  },
  methods : {
    async reorderhistory(){
      this.loading = true;
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          /* additional parameters */
        }
      };
      
      const response = await reorderhistory(1);
      this.list = response.data.pageList;
      this.pagination = response.data.pagination;
      this.loading = false;
    },
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("reorderTab") || "0");
      setCurrentPageTitle("Reorder");

    });
    
  }
});
